import React, { Component, createContext } from 'react';
import { compose, graphql, withApollo } from 'react-apollo';
import { GET_CUPS_CODES } from '../graphql/types/billing/querys';
import { GET_SERVICES } from '../graphql/types/servicesModule/queries';
import { GET_EPS } from '../graphql/types/eps/queries';
import { DELETE_CUPS, SAVE_CUPS, UPDATE_CUPS } from '../graphql/types/billing/mutations';

export const context = createContext();

const { Provider } = context;

class BillingProvider extends Component {
    state = {
        cups: {
            data: [],
            loading: false,
            filters: {
                service: '',
                eps: '',
                code: '',
                search: ''
            },
            pagination: {
                page: 1,
                perPage: 10
            },
            total: 0,            
            showForm: false,
            deleteVerification: false,
            cupsIdToDelete: null
        },
        services: [],
        securers: [],
        cupsFormData: {
            service: {},
            eps: '',
            code: '',
        },
    };

    getCups = async () => {
        const { filters, pagination } = this.state.cups;

        const response = await this.props.client.query({
            query: GET_CUPS_CODES,
            variables: {
                filters,
                pagination
            }
        });

        const { data, total } = response.data.getCupsCodes;

        this.setState({
            cups: {
                ...this.state.cups,
                data,
                total
            }
        });
    }

    setCupsPaginationData = (field, value) => {
        this.setState({ 
            cups: {
                ...this.state.cups,
                pagination: {
                    ...this.state.cups.pagination,
                    [field]: value
                }
            }
        });
    }

    handleCupsPageChange = page => {
        this.setCupsPaginationData('page', page);
    }

    handleCupsRowsChange = (perPage, page) => {
        this.setCupsPaginationData('perPage', perPage);
    }

    setCupsFilter = (field, value) => {
        const cups = this.state.cups;

        this.setState({
            cups: {
                ...cups,
                filters: {
                    ...cups.filters,
                    [field]: value
                }
            }    
        });
    }

    setCupsFormDataField = (field, value) => {
        console.log(value);
        this.setState({
            cupsFormData: {
                ...this.state.cupsFormData,
                [field]: value
            }
        });
    }

    setShowCupsForm = show => {
        this.setState({
            cups: {
                ...this.state.cups,
                showForm: show
            }
        });
    }

    showCupsForm = () => {
        this.setShowCupsForm(true);
    }

    hideCupsForm = () => {
        this.setShowCupsForm(false);
    }

    getServices = async (name) => {
        const response = await this.props.client.query({
            query: GET_SERVICES,
            variables: {
                page: 1
            }
        });

        const { data } = response.data.getServices;

        this.setState({
            services: data.map(item => ({
                label: item.name,
                value: item._id
            }))
        });
    }

    getSecurers = async () => {
        const response = await this.props.client.query({
            query: GET_EPS
        });

        const { data } = response.data.allEps;

        this.setState({
            securers: data.map(item => ({
                label: item.nombre,
                value: item._id
            }))
        });
    }

    saveCups = async () => {
        const { service, eps, code } = this.state.cupsFormData;

        const response = await this.props.saveCups({
            variables: {
                service: service.value,
                eps: eps.value,
                code
            }
        });

        return response;
    }

    updateCups = async (data) => {
        const { _id, service, eps, code } = this.state.cupsFormData;

        const response = await this.props.updateCups({
            variables: {
                _id,
                service: service.value,
                eps: eps.value,
                code
            }
        });

        return response;
    }

    deleteCups = async () => {
        const response = await this.props.deleteCups({
            variables: {
                _id: this.state.cups.cupsIdToDelete
            }
        });

        return response;
    }

    resetCupsForm = () => {
        this.setCupsFormDataField('service', '');
        this.setCupsFormDataField('eps', '');
        this.setCupsFormDataField('code', '');
    }

    setCupsFormData = (data) => {
       const selectedService = this.state.services.find(item => item.label === data.service);
       const selectedEps = this.state.securers.find(item => item.label === data.eps);

        this.setState({
            cupsFormData: {
                _id: data._id,
                code: data.code,
                service: selectedService,
                eps: selectedEps
            }
        });
    }

    setShowDeleteVerification = (show, cupsId) => {
        this.setState({
            cups: {
                ...this.state.cups,
                deleteVerification: show,
                cupsIdToDelete: cupsId
            }
        });
    }

    showDeleteVerification = (cupsId) => {
        this.setShowDeleteVerification(true, cupsId);
    }

    hideDeleteVerification = () => {
        this.setShowDeleteVerification(false, null);
    }

    render() {
        return (
            <Provider 
                value={{
                    ...this.state,
                    getCups: this.getCups,
                    handleCupsPageChange: this.handleCupsPageChange,
                    handleCupsRowsChange: this.handleCupsRowsChange,
                    setCupsFilter: this.setCupsFilter,
                    setCupsFormDataField: this.setCupsFormDataField,
                    showCupsForm: this.showCupsForm,
                    hideCupsForm: this.hideCupsForm,
                    getServices: this.getServices,
                    getSecurers: this.getSecurers,
                    saveCups: this.saveCups,
                    updateCups: this.updateCups,
                    deleteCups: this.deleteCups,
                    resetCupsForm: this.resetCupsForm,
                    setCupsFormData: this.setCupsFormData,
                    showDeleteVerification: this.showDeleteVerification,
                    hideDeleteVerification: this.hideDeleteVerification
                }}
            >
                {this.props.children}
            </Provider>
        );
    }
}

export default compose(
    graphql(SAVE_CUPS, { name: 'saveCups' }),
    graphql(UPDATE_CUPS, { name: 'updateCups' }),
    graphql(DELETE_CUPS, { name: 'deleteCups' })
)(withApollo(BillingProvider));